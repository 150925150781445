import React, { useEffect, useMemo, useState } from 'react';
import styles from './Navigation.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton, Popover } from '@mui/material';
import {
  faBars,
  faChevronDown,
  faSignOutAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../store/auth/service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { Roles, User } from '../../domain/User';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { Locale } from '../../domain/Translation';
import { routes } from '../../config/Router/routes';

export type Props = {
  onLogout: () => void;
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  selectedRole: Roles | null;
  selectedLocale: Locale;
  currentUser: User | null;
  selectedCompany: number | null;
};

const MOBILE_BREAK_POINT = 900;

export const Navigation = ({
  onLogout,
  onDrawerClick,
  isMobileMenuOpen,
  selectedLocale,
  selectedRole,
  currentUser,
  selectedCompany,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { width } = useWindowSize();
  const intl = useIntl();

  const roleSelections = useMemo(() => {
    if (!currentUser) {
      return [];
    }

    return (
      currentUser.userCompanyRoles
        ?.filter(
          (userCompanyRole) => userCompanyRole.companyId === selectedCompany,
        )
        .map((userCompanyRole) => ({
          label: translate(intl, `ROLES.${userCompanyRole.role}`),
          isActive: selectedRole === userCompanyRole.role,
          visible: true,
          role: userCompanyRole.role,
        })) ?? []
    );
  }, [currentUser, selectedRole, selectedCompany]);

  const handleRoleSwitcher = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleRoleClick = (clickedRole: Roles) => {
    localStorage.setItem('selectedRole', clickedRole);
    setIsPopoverOpen(false);
    window.location.href = getLocaleUrl(
      clickedRole === Roles.USER ? routes.dashboard : routes.mainTasks.list,
      selectedLocale,
    );
  };

  useEffect(() => {
    const companyRoles =
      currentUser?.userCompanyRoles?.filter(
        (userCompanyRole) => userCompanyRole.companyId === selectedCompany,
      ) ?? [];

    if (
      companyRoles.length > 0 &&
      !companyRoles.some(
        (userCompanyRole) => userCompanyRole.role !== selectedRole,
      ) &&
      selectedRole !== companyRoles[0].role
    ) {
      handleRoleClick(companyRoles[0].role);
    }
  }, [selectedRole, selectedCompany, currentUser]);

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.iconItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
        </div>
        <div className={styles.iconItems}>
          {currentUser && roleSelections.length > 1 && (
            <IconButton onClick={handleRoleSwitcher}>
              <FontAwesomeIcon icon={faChevronDown} fixedWidth size="sm" />
            </IconButton>
          )}
          <LanguageSwitcher />
          <IconButton onClick={() => onLogout()}>
            <FontAwesomeIcon
              icon={faSignOutAlt as IconProp}
              fixedWidth
              size="sm"
            />
          </IconButton>
        </div>
      </header>
      <Popover
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <div className={styles.loginAs}>
            {translate(intl, 'NAVIGATION.SWITCH_ROLE', 'Switch to:')}
          </div>
          <ul className={styles.popoverList}>
            {roleSelections.map((roleSelection) => (
              <li
                key={roleSelection.label}
                className={cx(styles.popoverListItem, {
                  [styles.active]: roleSelection.isActive,
                })}
                onClick={() => handleRoleClick(roleSelection.role)}
              >
                {roleSelection.label}
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedRole: state.auth.role,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
  selectedCompany: state.company.selectedCompany,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './CompanySelectForm.module.scss';
import * as companyService from '../../store/company/service';
import { setSelectedCompany } from '../../store/company/actions';
import { useIntl } from 'react-intl';
import { Company } from '../../domain/Company';
import { translate } from '../../utility/messageTranslator/translate';
import Form from '../../common/Form/Form';
import FormCard from '../../common/FormCard/FormCard';
import TextField from '../../common/TextField/TextField';
import Alert from '../../common/Alert/Alert';
import Button from '../../common/Button/Button';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { getGlobalError } from '../../utility/error/httpErrorParser';
import { useForm } from '../../hooks/useForm/useForm';
import { HttpError } from '../../config/Axios/axios-instance';
import { routes } from '../../config/Router/routes';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { Locale } from '../../domain/Translation';
import { Roles, User } from '../../domain/User';

type Props = {
  isLoading?: boolean;
  error: HttpError;
  companyOptions: Company[];
  onCompanyFetch: () => void;
  onCompanySelect: (companyId: number) => void;
  onSuccessSelect?: () => void;
  selectedCompany: number | null;
  selectedLocale: Locale;
  currentUser: User | null;
};

type FormInputs = {
  companyId: string;
};

export const CompanySelectForm = ({
  isLoading,
  error,
  companyOptions,
  onCompanyFetch,
  onCompanySelect,
  onSuccessSelect,
  selectedCompany,
  selectedLocale,
  currentUser,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    onCompanyFetch();
  }, []);

  const companiesOptions = useMemo(
    () =>
      companyOptions.map((company: Company) => {
        return {
          value: company.id.toString(),
          label: company.name,
        };
      }) || [],
    [companyOptions],
  );

  const INPUTS = [
    {
      name: 'companyId',
      label: translate(intl, 'COMPANIES.SELECT_FORM_NAME'),
      type: 'autocomplete',
      validation: [
        {
          type: 'required',
        },
      ],
      options: [],
      value: selectedCompany?.toString() ?? '',
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    onCompanySelect(Number(submitInputs.companyId));

    const companyRole = currentUser?.userCompanyRoles?.find(
      (userCompanyRole) =>
        userCompanyRole.companyId.toString() ===
        submitInputs.companyId.toString(),
    )?.role;

    if (currentUser?.role !== Roles.ADMIN && companyRole) {
      localStorage.setItem('selectedRole', companyRole);
    }

    onSuccessSelect?.();

    window.location.href = getLocaleUrl(routes.dashboard, selectedLocale);
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSelectChange,
    onSetValidationErrors,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  useEffect(() => {
    if (companiesOptions) {
      setNewInputObject('companyId', {
        options: companiesOptions,
      });
    }
  }, [companiesOptions]);

  const globalError = getGlobalError(error);

  return (
    <div className={styles.companySelectForm}>
      <Form className={styles.form} error={error} onSubmit={onSubmit}>
        <FormCard>
          {globalError && (
            <Alert variant="danger" capitalize={false}>
              {globalError}
            </Alert>
          )}
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              type={input.type}
              options={input.options}
              onSelectChange={onSelectChange}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {translate(intl, 'COMPANIES.SELECT_COMPANY')}
          </Button>
        </FormCard>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.company.companyOptionsError,
  isLoading: state.company.companyOptionsLoading,
  companyOptions: state.company.companyOptions,
  selectedCompany: state.company.selectedCompany,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyFetch: () => dispatch(companyService.fetchCompanyOptions()),
  onCompanySelect: (selectCompanyId: number) =>
    dispatch(setSelectedCompany(selectCompanyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelectForm);
